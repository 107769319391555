function getTimeFormat(s, d) {
    let formatime = new Date(s * 1000).toISOString()

    if(!d) return
    
    if(d === "dough") {
        return formatime.substring(14,19) + "m"
    } else {
        if(formatime.substring(14,16) !== "00")
            return formatime.substring(14,22)
        else return formatime.substring(17,22) 
    }
}

export default getTimeFormat