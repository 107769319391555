import xtlog from '../logs/xt_log.json'

let dList =[]
xtlog.forEach(x => {
    let dListObj = {}
    let indx = x.challenges.findIndex(c => c.type === "del")
    if (indx >= 0) {
        dListObj.name = x.name;
        dListObj.store = x.store;
        dListObj.bestTime = x.challenges[indx].bestTime
        if(x.challenges[indx].firstTime) dListObj.firstTime = x.challenges[indx].firstTime
        dList.push(dListObj)
    }
})

dList.sort(function(a,b) {
    return a.bestTime - b.bestTime
})



function NextBeat(props) {
    
    let nxt = dList.find(c => c.bestTime > props.time)
    let remaining = dList.slice(dList.findIndex(c => c.bestTime > props.time))

    if(!nxt) return

    // Ahead of   {someone}                                  by  {x} seconds
    // You beat   {someone}   (and {x} others)   with a lead of  {x} seconds

    let str1 = (props.running) ? "Ahead of " : "You beat ";
    let str2 = <span className='text-light'>{nxt.name.split(' ')[0]}</span>
    let str3 = (!props.running) ? 
                    (remaining.length-1) ?
                        <span> and <span className='text-light'>{remaining.length -1}</span> others</span>
                        : ""
                    : ""
    let str4 = (props.running) ? " by " : <><br/>with a lead of </>
    let str5 = <span className='text-info'>{(nxt.bestTime-props.time).toFixed(2)}</span>

    if(props.time === 0) return
    else return <sup className='text-secondary pb-2'>{str1}{str2}{str3}{str4}{str5} seconds.</sup>
}

export default NextBeat