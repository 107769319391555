import getChIcon from '../functions/getChallengeIcon'
import xt_log from '../logs/xt_log.json'

import LBRow from './LBRow'

function LB(props) {

    let dList =[]
    xt_log.forEach(x => {
        let dListObj = {}
        let indx = x.challenges.findIndex(c => c.type === props.dataID)
        if (indx >= 0) {
            dListObj.name = x.name;
            dListObj.store = x.store;
            dListObj.bestTime = x.challenges[indx].bestTime
            if(x.challenges[indx].firstTime) dListObj.firstTime = x.challenges[indx].firstTime
            dList.push(dListObj)
        }
        
    })

    dList.sort(function(a,b) {
        return a.bestTime - b.bestTime
    })

    // let nulldel = ''
    // if(props.dataID === 'del'){
    //     nulldel = <div>
    //         <p><span style={{color:'springgreen'}}>Jay</span> nuked it lol</p>
    //         <img className='w-100'src='https://bestanimations.com/media/nuclear-explosions/234085459star-explosion-animation.gif' alt=''></img>
    //     </div>
    // }

    return(
        <div id={props.dataID} className='LB-card x-shadow'>
            {(!props.ismini) ? <img className="title-icon" src={getChIcon(props.dataID)} alt={props.title}></img> : null}<p className='text-uppercase h2'>{props.title}</p>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <table className='table table-borderless table-responsive'>
                        <thead className='text-secondary'>
                            <th className='priority-2 text-left' scope='col'>Rank</th>
                            <th className='priority-1' scope='col'>Name</th>
                            <th className='priority-2' scope='col'>Current Time</th>
                            {(!props.ismini) ? <th className='priority-3' scope='col'>Initial Time</th> : null}
                        </thead>
                        <tbody>
                            {dList.map((row, i) => <LBRow row={row} rank={i} dataID={props.dataID} ismini={props.ismini}/>)}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default LB