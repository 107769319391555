import xmake from '../img/xtrain_make.png'
import xcut from '../img/xtrain_cut.png'
import xdel from '../img/xtrain_del.png'
import xdough from '../img/xtrain_dough.png'
import xdispatch from '../img/xtrain_dispatch.png'

function TheNav() {
    return (
        <nav id="theTopNav" className="x-shadow navbar fixed-bottom navbar-expand navbar-dark bg-dark">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav m-auto">
                    <a className="jb nav-item nav-link" href="#make"><img className="nav-icon" src={xmake} alt="Make"></img></a>
                    <a className="jb nav-item nav-link" href="#cut"><img className="nav-icon" src={xcut} alt="Cut"></img></a>
                    <a className="jb nav-item nav-link" href="#del"><img className="nav-icon" src={xdel} alt="Delivery"></img></a>
                    <a className="jb nav-item nav-link" href="#dough"><img className="nav-icon" src={xdough} alt="Dough"></img></a>
                </div>
                <a className="jb nav-item nav-link" href="/delpatch"><img className="nav-icon" src={xdispatch} alt="Delpatch"></img></a>
                </div>
            </div>
        </nav>
    )
}

export default TheNav