import React, { useState } from 'react';

// Classic, XL, Deep, Mini, Cheesy, Thins, Scrolls, GlutenFree

function Tagger(props) {

    const [inputs, setInputs] = useState({});
    const [mSent, setMSent] = useState(false);

    function setVisibilty(bool) {
        if(!bool) return "d-none"
        else return
    }

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    function printStuff() {
        return
    }

    return(
        <header className="container-fluid x-shadow">
            <div className='h1 jb m-5'>Contact Me</div>
			<form onSubmit={printStuff}>
				<div className="container">
					<div className={setVisibilty(!mSent) + ' row'}>
                        <div className="col-12">
                            <input
                                className="c-inp p-2 px-3 m-4 jb fs-6"
                                type="text" 
                                name="c_name" 
                                value={inputs.c_name || ""} 
                                onChange={handleChange}
                                placeholder="Name"
                                autoComplete="off"
                                required
                            />
                        </div>
                        <div className="col-12">
                            <input
                                className="c-inp p-2 px-3 m-4 jb fs-6"
                                type="email" 
                                name="c_email" 
                                value={inputs.c_email || ""} 
                                onChange={handleChange}
                                placeholder="Email"
                                required
                            />
                        </div>
                        <div className="col-12">
                            <textarea
                                className="c-inp p-2 px-3 m-4 jb fs-6"
                                type="textarea" 
                                rows={5}
                                name="c_message"
                                value={inputs.c_message || ""} 
                                onChange={handleChange}
                                placeholder="Message"
                                autoComplete="off"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className='btn-hld'>
                    <input type="submit" className={setVisibilty(!mSent) + ' btn btn-rd p-2 px-3 m-4 jb'} value="Send"/>
                    <p className={setVisibilty(mSent) + ' p-2 m-4 jb fs-6 g81f'}>Message sent!</p>
                    <p className={setVisibilty(mSent) + ' p-2 m-4 jb fs-6'}> Thanks for writing to me, {inputs.c_name}. <br/>I'll get back to you as soon as I can.</p>
                </div>
            </form>
        </header>
    )
}

export default Tagger