import '../App.css';
import '../scroll.css';
import Cred from '../components/Cred'
import xhead from '../img/xtrain_header.png';


function Home() {
  return (
    <div className="App">
      <header className='App-header'>
        <div className='container xhead align-items-center justify-items-center'>
          <img className='img-fluid xhead-splash' src={xhead} alt='Xtrain'></img>
          <div className="btn-group btn-group m-1 appear-a blurback" role="group" aria-label="Basic example">
            <a type="button" href="/lb" className="btn btn-light">The LB</a>
            <a type="button" href="/dt4s" className="btn btn-outline-light">DT4S</a>
          </div>
        </div> 
        <Cred/>
      </header>
    </div>
  );
}

export default Home;
