import '../App.css';
import '../scroll.css';
import rx from '../img/rx.png'
import Tagger from '../components/Tagger';


function DohTag() {
  return (
    <div className="App">
      <header className='App-header'>
        <div className='container xhead align-items-center justify-items-center'>
          <p className='text-uppercase h2'>DohTag</p>
          <Tagger/>
        </div> 
        <header id="credby" className='justify-items-center'>
            <div className='mt-5 w-100'>
                {/* <text>coordinated by <span style={{color:'springgreen'}}>jay</span>&emsp;&emsp;|&emsp;&emsp;designed by<img className='lvl-icon p-1' src={rx} alt=''></img><span id='rxg'>riju</span></text> */}
                {/* <text>developed by<img className='lvl-icon p-1' src={rx} alt=''></img><span id='rxg'>riju</span></text> */}
                <a href='https://dominosanz.workplace.com/chat/t/100064954276030' target="_blank" rel="noopener noreferrer"><img className='lvl-icon mt-5' src={rx} alt='riju'></img></a>
            </div>
        </header>
      </header>
    </div>
  );
}

export default DohTag;
