import getLevelIcon from '../functions/getLevelIcon.js'
import getTimeFormat from '../functions/getTimeFormat.js';

function XTLBRow(props) {

    function getC(cl, d) {
        let cd = {}
        cl.forEach(c => {
            if(c.type === d) cd = c
        });
        return cd
    }

    return(
        <div className='LB-card p-2 m-1'>
            <div className='container'>
                <div className='row align-items-center justify-content-center'>
                    <div className='col-md-auto d-flex align-items-center justify-content-center'>
                        <span className="badge bg-dark text-secondary m-1">{props.u.store}</span>
                        <img className="lvl-icon p-0 m-1" src={getLevelIcon(props.u.xtrank)} alt={props.u.xtrank}></img> 
                        <p className='text-uppercase m-1'>{props.u.name}</p> 
                    </div>
                    {['make', 'cut', 'del', 'dough'].map(d => 
                        <div class="col-1 d-flex align-items-center justify-content-center">
                            <img className="lvl-icon m-1 p-1" src={getLevelIcon(getC(props.u.challenges, d).rank)} alt={d} title={`${d.toUpperCase()}\nRank: ${getC(props.u.challenges, d).rank}\nTime: ${getTimeFormat(getC(props.u.challenges, d).bestTime, d)}`}></img>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default XTLBRow