import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import Home from './pages/Home';
import LBHome from './pages/LBHome';
import DT4S from './pages/DT4S'
import DohTag from './pages/DohTag';
import reportWebVitals from './reportWebVitals';

// function Index() {
//   return (
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   )
// }

function Index() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home/>} />
        <Route path="lb" element={<LBHome/>} />
        <Route path="dt4s" element={<DT4S/>} />
        <Route path="dohtag" element={<DohTag/>} />
      </Routes>
    </BrowserRouter>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);
export default Index


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
