import xlvl0 from '../img/xtrain_lvl0.png'
import xlvl1 from '../img/xtrain_lvl1.png'
import xlvl2 from '../img/xtrain_lvl2.png'
import xlvl3 from '../img/xtrain_lvl3.png'
import xlvl4 from '../img/xtrain_lvl4.png'

function getLevelIcon(l) {
    if(l === 4) return xlvl4
    else if (l === 3) return xlvl3
    else if (l === 2) return xlvl2
    else if (l === 1) return xlvl1
    else return xlvl0
}

export default getLevelIcon