import xt_log from '../logs/xt_log.json'
import getLevel from '../functions/getLevel.js'
import getChIcon from '../functions/getChallengeIcon'
import XTLBCard from './XTLBCard'
import XTLBRow from './XTLBRow'

function XTLB() {

    let rankedList = []
    let unrankedList = []

    xt_log.forEach(u => {
        u.challenges.forEach((c, ci) => {
            u.challenges[ci].rank = getLevel(u.challenges[ci].bestTime, u.challenges[ci].type)
        })
        if(
            u.challenges.some(c => c.type === 'make')
            && u.challenges.some(c => c.type === 'cut') 
            && u.challenges.some(c => c.type === 'del') 
            && u.challenges.some(c => c.type === 'dough')
            ) {
                u.xtrank = 99
                u.challenges.forEach(c => {
                    if(c.rank < u.xtrank) u.xtrank = c.rank
                })
                if (u.xtrank) rankedList.push(u)
                else unrankedList.push(u)
        console.log('xtlbc')
        } else u.xtrank = 0
    })

    rankedList.sort(function(a,b) {
        return b.xtrank - a.xtrank
    })

    return(
        <div className='LB-card x-shadow p-3'>
            {/* <p className='text-uppercase h2 m-4'>...</p> */}
            <img className="title-icon" src={getChIcon("xt")} alt={"xt"}></img>
            {rankedList.map(u => <XTLBCard u={u}/>)}
            <br/>
            {unrankedList.map(u => <XTLBRow u={u}/>)}
        </div>
    )

}

export default XTLB