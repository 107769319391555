import getLevelIcon from '../functions/getLevelIcon.js'
import getLevel from '../functions/getLevel.js'
import getTimeFormat from '../functions/getTimeFormat.js'

function getStoreName(id) {
    if(id === "002") return "Kedron"
    else if(id === "004") return "Strathpine"
    else if(id === "480") return "Chermside"
    else return "Albany Creek"
}

function getRowDesign(r) {
    if(r === 0) return "rank-gold"
    else if(r === 1) return "rank-silver"
    else if (r === 2) return "rank-bronze"
    else return "rank-zero"
}

function LBRow(props) {

    return(
        <tr className={getRowDesign(props.rank)}>
            <td className='priority-2 text-left d-flex align-items-center'>
                {(!props.ismini) ? <img className="lvl-icon" src={getLevelIcon(getLevel(props.row.bestTime, props.dataID))} alt={getLevel(props.row.bestTime, props.dataID)}></img> : null } 
                <p className='m-0 badge'>{props.rank + 1}</p>
            </td>
            <td className="priority-1 text-left oneline-name"><span title={getStoreName(props.row.store)} className="badge bg-dark text-secondary">{props.row.store}</span> {props.row.name}</td>
            <td className='priority-2'>{getTimeFormat(props.row.bestTime.toFixed(2), props.dataID)}</td>
            {(!props.ismini) ? <td className='priority-3' title={"+ " + getTimeFormat((props.row.firstTime-props.row.bestTime).toFixed(2), props.dataID)}>{getTimeFormat(props.row.firstTime.toFixed(2), props.dataID)}</td> : null}
        </tr>
    )
}

export default LBRow