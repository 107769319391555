import rx from '../img/rx.png'
import xtlog from '../logs/xt_log.json'

function Cred() {
    return(
        <header id="credby" className='justify-items-center'>
            <div className='mt-5 w-100'>
                {/* <text>coordinated by <span style={{color:'springgreen'}}>jay</span>&emsp;&emsp;|&emsp;&emsp;designed by<img className='lvl-icon p-1' src={rx} alt=''></img><span id='rxg'>riju</span></text> */}
                {/* <text>developed by<img className='lvl-icon p-1' src={rx} alt=''></img><span id='rxg'>riju</span></text> */}
                <a href='https://dominosanz.workplace.com/chat/t/100064954276030' target="_blank" rel="noopener noreferrer"><img className='lvl-icon mt-5' src={rx} alt='riju'></img></a>
            </div>
            <p className='text-secondary m-3'>LB LAST UPDATED: {xtlog[0].time}</p>
        </header>
    )
}

export default Cred