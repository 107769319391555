function getLevel(t, d) {
    if(d === "make") {
        if(t <= 13) return 4
        else if (t <= 17) return 3
        else if (t <= 20) return 2
        else if (t <= 25) return 1
        else return 0
    }
    if(d === "cut") {
        if(t <= 13) return 4
        else if (t <= 16) return 3
        else if (t <= 20) return 2
        else if (t <= 27) return 1
        else return 0
    }
    if(d === "del") {
        if(t <= 13) return 4
        else if (t <= 15) return 3
        else if (t <= 18) return 2
        else if (t <= 23) return 1
        else return 0
    }
    if(d === "dough") {
        if(t <= 270) return 4
        else if (t <= 300) return 3
        else if (t <= 360) return 2
        else if (t <= 420) return 1
        else return 0
    }
}

export default getLevel