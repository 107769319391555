import getChIcon from '../functions/getChallengeIcon'
import LB from './LB'

function MiniLB(props) {

    const minich = ['sauce']

    function PillTab(props) {
        return(
            <li className="nav-item" role="presentation">
            <div className="btn bg-transparent p-0" id={"pills-"+props.d+"-tab"} data-bs-toggle="pill"
                data-bs-target={"#pills-"+props.d} type="button" role="tab"
                aria-controls={"pills-"+props.d} aria-selected="true">
                <img className="nav-icon m-3" src={getChIcon(props.d)} alt={props.d} href={"#"+props.d}></img>
            </div>
            </li>
        )
    }
        
    return(
        <div id={props.dataID}>

            {/* side nav */}
            {/* <div className='d-flex fixed-bottom justify-content-start h-100 align-items-center appear-a z2'>
                <ul className="d-none d-sm-block nav flex-column nav-pills mb-3" id="pills-tab" role="tablist">
                    {minich.map(m => <PillTab d={m}/>)}
                </ul>
            </div> */}

            {/* bottom nav */}
            {/* <div className='d-block d-sm-none d-flex bg-blackish fixed-top justify-content-center w-100 align-items-center appear-a backblur'>
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    {minich.map(m => <PillTab d={m}/>)}
                </ul>
            </div> */}
            
            <div className="tab-content p-3" id="pills-tabContent">
                {minich.map(m => 
                    <div className="tab-pane show active" id={"pills-"+m} role="tabpanel" aria-labelledby={"pills-"+m}>
                        <LB dataID={m} title={m[0].toUpperCase() + m.slice(1) + " Challenge"} ismini={true}/>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MiniLB