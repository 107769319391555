import '../App.css';
import '../scroll.css';
import TheNav from '../components/TheNav';
import LB from '../components/LB';
import XTLB from '../components/XTLB';
import Cred from '../components/Cred'
import xhead from '../img/xtrain_header.png';
import xmake from '../img/xtrain_make.png'
import xcut from '../img/xtrain_cut.png'
import xdel from '../img/xtrain_del.png'
import xdough from '../img/xtrain_dough.png'
import MiniLB from '../components/MiniLB';
import getChIcon from '../functions/getChallengeIcon';


function LBHome() {

  function PillTab(props) {
    return(
      <li className="nav-item" role="presentation">
        <div className="btn bg-transparent p-0" id={"pills-"+props.d+"-tab"} data-bs-toggle="pill"
          data-bs-target={"#pills-"+props.d} type="button" role="tab"
          aria-controls={"pills-"+props.d} aria-selected="true">
          <img className="nav-icon m-3" src={getChIcon(props.d)} alt={props.d} href={"#"+props.d}></img>
        </div>
      </li>
    )
  }

  return (
    <div className="App">
      <header className='App-header py-5 justify-content-start'>
        
        <div className="tab-content p-3" id="pills-tabContent">
          <div className="tab-pane show active" id="pills-xt" role="tabpanel" aria-labelledby="pills-xt"><XTLB/></div>
          <div className="tab-pane" id="pills-make" role="tabpanel" aria-labelledby="pills-make"><LB dataID="make" title="Make Challenge" ismini={false}/></div>
          <div className="tab-pane" id="pills-cut" role="tabpanel" aria-labelledby="pills-cut"><LB dataID="cut" title="Cut Challenge" ismini={false}/></div>
          <div className="tab-pane" id="pills-del" role="tabpanel" aria-labelledby="pills-del"><LB dataID="del" title="Del Challenge" ismini={false}/></div>
          <div className="tab-pane" id="pills-dough" role="tabpanel" aria-labelledby="pills-dough"><LB dataID="dough" title="Dough Challenge" ismini={false}/></div>
          <div className="tab-pane" id="pills-mini" role="tabpanel" aria-labelledby="pills-mini"><MiniLB/></div>
        </div>
        
        {/* side nav */}
        <div className='d-flex fixed-bottom justify-content-end h-100 align-items-center appear-a z1'>
          <ul className="d-none d-sm-block nav flex-column nav-pills mb-3" id="pills-tab" role="tablist">
            <PillTab d="xt" />
            <PillTab d="make" />
            <PillTab d="cut"/>
            {/* <PillTab d="del"/> */}
            <PillTab d="dough"/>
            <PillTab d="mini"/>
          </ul>
        </div>

        {/* bottom nav */}
        <div className='d-block d-sm-none d-flex bg-blackish fixed-bottom justify-content-center w-100 align-items-center appear-a backblur'>
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <PillTab d="xt" />
            <PillTab d="make"/>
            <PillTab d="cut"/>
            {/* <PillTab d="del"/> */}
            <PillTab d="dough"/>
            <PillTab d="mini"/>
          </ul>
        </div>
        
      </header>
      
      {/* <XTLB/> */}
      {/* <TheNav/> */}
    </div>
  );
}

export default LBHome;
