import getLevelIcon from '../functions/getLevelIcon.js'
import getTimeFormat from '../functions/getTimeFormat.js';

function XTLBCard(props) {

    let xtrank = props.u.xtrank

    function getC(cl, d) {
        let cd = {}
        cl.forEach(c => {
            if(c.type === d) cd = c
        });
        return cd
    }

    return(
        <div className='LB-card my-3 mx-2 p-2'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <span className="badge bg-dark text-secondary p-1">{props.u.store}</span>
                            <img className="title-icon p-4" src={getLevelIcon(xtrank)} alt={xtrank}></img> 
                            <p className='text-uppercase h6 m-0'>{props.u.name}</p> 
                        </div>
                    </div>
                </div>
                <div className="row gx-10">
                    {['make', 'cut', 'del', 'dough'].map(d => 
                        <div class="col-3 align-items-center justify-content-center">
                            <p className='text-uppercase text-secondary m-0 text-center'>{d}</p> 
                            <img className="lvl-icon m-2" src={getLevelIcon(getC(props.u.challenges, d).rank)} alt={getC(props.u.challenges, d).rank}></img>
                            <p className='priority-3 text-uppercasem-0'>{getTimeFormat(getC(props.u.challenges, d).bestTime, d)}</p> 
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default XTLBCard