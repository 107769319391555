import xmake from '../img/xtrain_make.png'
import xcut from '../img/xtrain_cut.png'
import xdel from '../img/xtrain_del.png'
import xdough from '../img/xtrain_dough.png'
import xmvp from '../img/xtrain_mvp.png'

function getChIcon(d) {
    if(d === "make") return xmake
    if(d === "cut") return xcut
    if(d === "del") return xdel
    if(d === "dough") return xdough
    if(d === "xt") return xmvp
    else return 'https://i.imgur.com/EeBuhyU.png'
}

export default getChIcon