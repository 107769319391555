import '../App.css'
import { useState, useEffect } from 'react';
import getLevel from '../functions/getLevel';
import getLevelIcon from '../functions/getLevelIcon';
import xdel from '../img/xtrain_del.png'
import xdispatch from '../img/xtrain_dispatch.png'
import st002 from '../logs/streetStats/st_002.json'
import st004 from '../logs/streetStats/st_004.json'
import st480 from '../logs/streetStats/st_480.json'
import NextBeat from '../components/NextBeat';

const nostreet = {
        stName: " ",
        suburb: " ",
        pCode: 0,
        r: 0,
        complexity: 0
    }

function getStoreName(id) {
    if(id === "002") return "Kedron"
    else if(id === "004") return "Strathpine"
    else if(id === "480") return "Chermside"
    else if(id === "060") return "Albany Creek"
    else return ""
}

function getDiffiLabel(id) {
    if(id === 1) return "Easy"
    else if(id === 2) return "Medium"
    else if(id === 3) return "Hard"
    else return ""
}

function DT4S() {

    const [ store, setStore ] = useState("")
    const [ stList, setStList ] = useState([])
    const [ diffi, setDiffi ] = useState(0)
    const [ street, setStreet ] = useState(nostreet)
    const [ sTime, setSTime ] = useState(0);
    const [ sRunning, setSRunning ] = useState(false);
    const [ startTime, setStartTime ] = useState("")

    useEffect(() => {
        let interval;
        if (sRunning) {
            interval = setInterval(() => {setSTime((+new Date()) - startTime);}, 10);
        } else if (!sRunning) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [sRunning, startTime]);
    
    function getButtonStuff(stuff, id) {
        if(stuff === "st") {
            if(id === store) return getStoreName(id)
            else return id
        }
        else if(stuff === "sa") {
            if(id === store) return "text-uppercase active"
            else return ""
        }
        else if(stuff === "da") {
            if(id === diffi) return "text-uppercase active"
            else return ""
        }
        
    }

    function setVisibilty(bool) {
        if(!bool) return "d-none"
        else return
    }

    // ADDAC
    function StoreSelector() {
        return (
            <div className="btn-group btn-group m-1" role="group" aria-label="Basic example">
                <button type="button" onClick={() => {setStore("004"); setStList(st004)}} className={getButtonStuff("sa", "004" ) + " btn btn-outline-light"}>{getButtonStuff("st", "004")}</button>
                <button type="button" onClick={() => {setStore("002"); setStList(st002)}} className={getButtonStuff("sa", "002" ) + " btn btn-outline-light"}>{getButtonStuff("st", "002")}</button>
                <button type="button" onClick={() => {setStore("480"); setStList(st480)}} className={getButtonStuff("sa", "480" ) + " btn btn-outline-light"}>{getButtonStuff("st", "480")}</button>
            </div>
        )
    }

    function DiffiSelector() {
        return (
            <div className="btn-group btn-group m-1" role="group" aria-label="Basic example">
                <button type="button" onClick={() => setDiffi(1)} className={getButtonStuff("da", 1) + " btn btn-outline-light"}>Easy</button>
                <button type="button" onClick={() => setDiffi(2)} className={getButtonStuff("da", 2) + " btn btn-outline-light"}>Medium</button>
                <button type="button" onClick={() => setDiffi(3)} className={getButtonStuff("da", 3) + " btn btn-outline-light"}>Hard</button>
            </div>
        )
    }

    function Splashclaimer() {
        return (
            <div className={setVisibilty(!store || !diffi) + ' LB-card'}>
                <img className="title-icon" src={xdel} alt="del"></img>
                <p className='text-uppercase h2'>DELIVERY CHALLENGE</p>
                <p className='text-uppercase'>Random Address Generator</p>
                <br/>
                <StoreSelector/>
                <DiffiSelector/>
                <p className='text-warning mt-4'>Be aware of customers and your surroundings.</p>
            </div>
        )
    }

    function Controls() {
        return (
            <div className='fixed-bottom w-100 py-4'>
                <div className={setVisibilty(store && diffi)}>
                    <button type="button" 
                        className={setVisibilty(sRunning) + " btn btn-lg btn-outline-danger mx-2 active w-50"}
                        onClick={() => setSRunning(false)} 
                    >Stop</button>
                    <button type="button"
                        className={setVisibilty(!sRunning) + " btn btn-lg btn-outline-warning mx-2 backblur"}
                        onClick={() => {setStreet(nostreet); setStreet(nostreet); setDiffi(0); setStore(""); setSRunning(false); setSTime(0)}}
                    >↶</button>
                    <button type="button"
                        className={setVisibilty(!sRunning && !sTime && store && diffi) + " btn btn-lg btn-success mx-2"}
                        onClick={() => {setStreet(randomStreet()); setSTime(getPenaltyMS()); setSRunning(true); setStartTime((new Date()) - getPenaltyMS())}}
                    >Start</button>
                    <button type="button"
                        className={setVisibilty(!sRunning && sTime) + " btn btn-lg btn-info mx-2"}
                        onClick={() => {setStreet(nostreet); setSRunning(false); setSTime(0)}}
                    >Retry</button>
                    <p className='text-uppercase text-secondary m-0 pt-2'>{getStoreName(store)} {getDiffiLabel(diffi)}</p>
                </div>
            </div>
        )
    }

    function randomStreet() {
        if(diffi && stList) {
            let diffiList = stList.filter(st => st.complexity === diffi)
            return(diffiList[Math.floor(Math.random() * diffiList.length)])
        } else setStreet(nostreet)
        return street.stName
    }

    function getPenaltyMS() {
        if(diffi === 1) return 6000
        else if(diffi === 2) return 3000
        else return 0
    }

    return(
        <div className="App">
          <header className='App-header pb-5 pt-0'>
              <Splashclaimer/>
            <div className={setVisibilty(store && diffi) + ' LB-card x-shadow'}>
                <img className={setVisibilty(sTime-getPenaltyMS() > 5000 && getLevel(sTime/1000, "del")) + " lvl-icon mb-3 mt-0"}
                    src={getLevelIcon(getLevel(sTime/1000, "del"))} alt={getLevel(sTime/1000)}></img>
                <div className='LB-card h1 p-3 px-3 justify-content-center align-items-center mb-3'>
                    <p className={setVisibilty(street && sTime) + " text-uppercase text-secondary text-timehash m-0 m-1"}>&nbsp;{"" + (+startTime).toString(36)}</p>
                    <span>{("" + Math.floor((sTime / 60000) % 60)).slice(-2)}:</span>
                    <span>{("0" + Math.floor((sTime / 1000) % 60)).slice(-2)}:</span>
                    <span>{("00" + Math.floor((sTime / 1))).slice(-3)}</span>
                </div>
                <NextBeat time={sTime/1000} running={sRunning}/>
                <p className='h2 text-uppercase mt-1'>{street.stName}</p>
                <p className={setVisibilty((sTime-getPenaltyMS()) > 1500) + ' h6 text-secondary mb-0'}>{street.suburb}</p>
            </div>
          </header>
          {Controls()}
        </div>
    )
}

export default DT4S